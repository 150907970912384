import React from "react";
import PropTypes from "prop-types";

import {
  Outcode,
  PropertySize,
  Information,
} from "../../../formGroups/PropertyDetailsGroup";

export default function PropertyDetails({ control, register, getValues }) {


  return (
    <>
      <Outcode
        control={control}
        required={true}
        getValues={getValues}
        register={register}
      />
      <PropertySize control={control} required={true} register={register} />
      <Information control={control} required={true} register={register} rows={4}/>
    </>
  );
}

// PropTypes
PropertyDetails.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func,
};
